<template>
  <div id="user">
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <van-popup v-model="datePickerShow" :style="{ width: '80%' }">
      <van-datetime-picker v-model="qrDate" type="date" :min-date="minDate" :max-date="maxDate" :formatter="formatter" @confirm="confirmDate()" @cancel="cancelDate()" />
    </van-popup>
    <van-popup v-model="lockmoneyShow" :style="{ width: '80%', boxShadow: '0 4px 8px #ebedf0' }" @click-overlay="closelockmoneyShow()">
      <h3 style="margin:4vw 5vw">
        设置 最大亏损
        <!-- <van-tag color="#f2826a" @click="autoDemoAccount()">申请模拟号体验</van-tag> -->
      </h3>
      <p style="color:red;line-height: 20px;font-size: 14px;padding: 0 5vw;">
        提示：设置 最大亏损比例 28% 的 风险水平
        <br>
        相当于 购买大盘指数的风险水平
      </p>
      <van-row style>
        <van-col span="24">
          <div style="height:10%;margin:10vw">
            <!-- <cube-input
              v-model="percent"
              clearable
              :style="{margin:'2vw 20vw'}"
            />-->
            <van-slider v-model="percentNew" active-color="#f2826a" bar-height="2vh" :step="10" :min="0" :max="50">
              <div slot="button" class="custom-button">{{ percentNew }}%</div>
            </van-slider>
          </div>
        </van-col>
      </van-row>
      <van-row>
        <!-- <van-button>取 消</van-button> -->
        <van-button v-if="form.securities !== 2" style="width:100%" @click="setLockPercent()">立刻锁定</van-button>
      </van-row>
    </van-popup>
    <van-popup v-model="editsecuritiesShow" :style="{ width: '80%', boxShadow: '0 4px 8px #ebedf0' }">
      <h3 style="margin:5px 20px">
        设置券商信息
        <!-- <van-tag color="#f2826a" @click="autoDemoAccount()">申请模拟号体验</van-tag> -->
      </h3>
      <van-row style>
        <van-col span="24">
          <van-field v-model="securitiesname" type="text" label="券商" clearable required readonly placeholder="点击请选择券商" @click="selectSecurities()">
            <van-icon slot="right-icon" name="arrow-down" @click="selectSecurities()" />
          </van-field>
          <!-- {{ form }} -->
          <van-field v-if="form.securities !== 2" v-model="form.securitiesUsername" type="text" label="账号" clearable required placeholder="请填写账号" maxlength="15">
            <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
          </van-field>
          <van-field v-if="form.securities !== 2" v-model="form.securitiesPassword" type="password" label="交易密码" clearable required placeholder="请输入券商登录密码" />
          <van-field v-if="needCpass" v-model="form.securitiesCpass" type="password" label="通信密码" clearable placeholder="请输入通信密码" />
          <van-field v-if="form.securities == 7" v-model="form.securitiesCpass" type="password" label="通信密码" clearable placeholder="请输入通信密码" />
          <van-field v-if="form.securities == 7" v-model="comment" type="textarea" label="备注" clearable required placeholder="请备注您希望对接的券商名称，营业部等信息。" maxlength="100">
            <!-- <van-icon slot="left-icon" name="https://b.yzcdn.cn/vant/icon-demo-1126.png" /> -->
          </van-field>
        </van-col>
      </van-row>
      <van-row>
        <!-- <van-button>取 消</van-button> -->
        <van-button v-if="form.securities !== 2" style="width:100%" @click="editSecurities()">立刻更新</van-button>
      </van-row>
    </van-popup>
    <van-popup v-model="editphoneShow" :style="{ width: '80%' }">
      <van-row>
        <van-col>新手机号: field 验证码: 确定</van-col>
      </van-row>
    </van-popup>
    <div class="card">
      <van-cell>
        <!-- 使用 title 插槽来自定义标题 -->
        <template slot="title">
          <h2 class="custom-title">
            <span style="margin-right:2vw">{{
                username ? username : "未登录"
              }}</span>
            <van-icon v-if="username && isvip" name="https://static.ainvestcn.com/icon/vip-a.png" />
            <van-icon v-if="username && !isvip" name="https://static.ainvestcn.com/icon/vip-d.png" />
            <van-button v-if="username && isWeiXin" size="mini" style="float:right" @click="thelogout()">登出</van-button>
            <br>
            <!-- {{ exptime }} -->
            <!-- todo -->
            <!-- <span v-if="!isvip&&exptime>0" style="color:red">试用结束</span>
            <span v-if="!isvip&&exptime===0" style="color:red">您已试用5天，明天将暂停自动交易和选策略功能</span>
            <span v-if="!isvip&&exptime<0" >{{ -exptime }}天后试用结束</span>-->
            <!-- <span
              v-if="!isvip && uinfo2.securities && uinfo1.strategy_name && yidaochecked"
              style="color:red"
            >试用版到期时间 {{ tryExpTime }}</span> -->
            <span v-if="isvip">到期时间 {{ stopTime }}</span>
            <span v-else>立刻成为会员，享受超额收益-></span>
            <van-button v-if="!isvip" style="float:right" color="#f2826a" size="mini" @click="vip()">购买</van-button>
            <!-- <van-button v-if="!isvip&&exptime《0" style="float:right" size="mini" @click="vip()">开通VIP</van-button> -->
            <van-button v-if="isvip" style="float:right" size="mini" @click="vip()">续费</van-button>
            <!-- todo  -->
            <van-button v-if="!username && isWeiXin" size="mini" style="float:right" @click="toreglogin()">登陆</van-button>
          </h2>
        </template>
        <!-- 不登陆不显示 -->
        <template v-if="username" slot="label">
          <!-- <div>手机号：{{ phonenumber?phonenumber:'未绑定' }}
            <van-button v-if="!phonenumber" style="float:right" size="mini" @click="setPhone()">绑定</van-button>
            <van-button v-if="phonenumber" style="float:right" size="mini" @click="setPhone()">修改</van-button>
          </div>-->
          <!-- <div>
            <span style="font-size:14px;margin-right:20px">
              一刀智投 3.0
            </span>
            <van-switch
              v-model="yidaochecked"
              size="14px"
              active-color="#07c160"
              inactive-color="#ee0a24"
              style="margin-top:2px"
              @change="toggleYidao()"
            />
          </div>-->
        </template>
      </van-cell>
      <!-- 不登陆不显示 -->
      <!-- <van-cell v-if="username&&special"> -->
      <van-cell v-if="false">
        <template slot="title">
          定制策略： 可转债
          <!-- <van-tag>查看</van-tag> -->
          <!-- <van-tag v-if="uinfo1.strategy_name" plain style="margin-left:10px" >查看策略表现</van-tag> -->
        </template>
        <template>
          <div slot="right-icon">
            <van-tag color="#f2826a" plain>联系客服恢复普通策略</van-tag>
            <!-- <van-button size="mini" @click="choseStrategy()">{{
              uinfo1.strategy_name ? "更换" : "立刻选择"
            }}</van-button> -->
          </div>
        </template>
      </van-cell>
      <!-- <van-cell v-if="username&&!special"> -->
      <van-cell v-if="username">
        <template slot="title">
          <div style="position: relative;z-index: 10;">当前策略：{{ uinfo1.strategy_name ? uinfo1.strategy_name : "未订阅" }}</div>
          <!-- <van-tag>查看</van-tag> -->
          <!-- <van-tag v-if="uinfo1.strategy_name" plain style="margin-left:10px" >查看策略表现</van-tag> -->
          <div class="cellTips" style="left: 150px;" v-if="!uinfo1.strategy_name" @click="choseStrategy()">
            第二步：选择策略
          </div>
        </template>
        <template>
          <div slot="right-icon">
            <van-button size="mini" @click="choseStrategy()">{{
                uinfo1.strategy_name ? "更换" : "立刻选择"
              }}</van-button>
          </div>
        </template>
      </van-cell>
      <van-cell v-if="username">
        <template slot="title">
          一刀智投3.0 ({{ yidaochecked ? "已开启" : "未开启" }})

          <div class="cellTips" style="left: 155px;" v-if="!yidaochecked">
            {{!uinfo1.strategy_name ? '第三步：开启一刀智投' : '请开启一刀智投'}}
          </div>
        </template>
        <template>
          <div slot="right-icon">
            <van-switch v-model="yidaochecked" size="14px" active-color="#f2826a" inactive-color="rgb(198, 193, 193)" style="margin-top:2px" :disabled="isfrozen ? true : false" @change="toggleYidaoBtn()" />
          </div>
        </template>
      </van-cell>
      <!-- <van-cell v-if="username && yidaocheckedStatus" style="padding: 4px 16px;">
        <template slot="title">
          <p class="yidaocheckedStatus">{{yidaocheckedStatus}}</p>
        </template>
      </van-cell> -->
      <van-cell>
        <template slot="title">风险锁定({{
            percent && percent !== 0 ? percent + "%" : "未锁定"
          }})</template>
        <template>
          <div slot="right-icon">
            <van-switch v-model="lockmoney" size="14px" active-color="#f2826a" inactive-color="rgb(198, 193, 193)" style="margin-top:2px" @change="toggleLockmoney()" />
          </div>
        </template>
      </van-cell>
      <van-cell v-if="!uinfo2.securities && username">
        <template slot="title">
          券商信息（未录入）

          <div class="cellTips" style="left: 140px;" @click="editsecuritiesShow = true">
            第一步：录入券商信息
          </div>
        </template>
        <template>
          <div slot="right-icon">
            <!-- 录入不生效 -->
            <van-button size="mini" @click="editsecuritiesShow = true">录入</van-button>
          </div>
        </template>
      </van-cell>
      <van-collapse v-if="username && uinfo2.securities" v-model="activeNames">
        <van-collapse-item name="1">
          <div slot="title">
            当前券商：{{
              uinfo2.securitiesName === "模拟炒股"
                  ? "未绑定实盘"
                  : uinfo2.securitiesName
            }}
          </div>
          <van-row>
            <van-col>
              券商：{{ uinfo2.securitiesName }}
              <br>
              券商账号：{{ uinfo2.securitiesUsername }}
              <br>
              <!-- 交易密码：*****{{ uinfo2.securitiesPassword.substring(5) }} -->
              {{
                uinfo2.securitiesPassword == ""
                    ? ""
                    : "交易密码：*****" +
                    uinfo2.securitiesPassword.substring(5) +
                    "\n"
              }}
              <br>
              {{
                uinfo2.requireFileds == 1 && (uinfo2.securitiesCpass !== "" && uinfo2.securitiesCpass !== null)
                    ? "通信密码：*****" +
                    uinfo2.securitiesCpass.substring(5) +
                    "\n"
                    : ""
              }}
              <!-- 余额：{{ uinfo1.Total_account?uinfo1.Total_account:'0' }} -->
            </van-col>
            <van-col>
              <van-button style="margin-left:40px" size="small" @click="editsecuritiesShow = true">修改信息</van-button>
            </van-col>
          </van-row>
        </van-collapse-item>
      </van-collapse>
      <!-- 券商信息 end -->
    </div>
    <!-- 券商信息 start -->
    <div class="card">
      <van-row gutter="20" style="margin:1vw;margin-bottom:2vw">
        <van-col span="12">
          最新盈亏：
          <br>
          {{ uCustomerReturn.Profit_and_loss ? uCustomerReturn.Profit_and_loss : "暂无数据" }}
        </van-col>
        <van-col span="12">
          总额：
          <br>
          {{ uCustomerReturn.Total_account ? uCustomerReturn.Total_account : "暂无数据" }}
        </van-col>
      </van-row>
      <van-row gutter="20" style="margin:1vw;margin-bottom:2vw">
        <van-col span="12">
          累计收益：
          <br>
          {{ uCustomerReturn.Total_return ? uCustomerReturn.Total_return : "暂无数据" }}
        </van-col>
        <van-col span="12">
          年化收益：
          <br>
          {{ uCustomerReturn.Year_return ? uCustomerReturn.Year_return : "暂无数据" }}
        </van-col>
      </van-row>
      <!-- <div id="piga" class="line" :style="{width:cubeWidth,height:'220px'}" /> -->
    </div>
    <div class="card" style>
      <div id="piga" class="line" :style="{ width: cubeWidth, height: '200px' }" />
    </div>
    <van-cell v-if="username">
      <template slot="title">
        <h3>
<!--          策略持仓-->
          <span style="float:right;margin-right:10px" @click="datePickerShow = true">
            <van-icon name="https://static.ainvestcn.com/icon/c1.png" style="margin-right:5px" />
            {{ dateOfDatetime(qrDate) }}
          </span>
        </h3>
      </template>
      <template slot="label">
        <p>
          <!-- {{ record.lenght>0?'数据渲染中':'暂时没有信息' }} -->
        </p>
      </template>
    </van-cell>
    <van-tabs v-model="active">
      <!-- <van-tab title="历史持仓">
        <van-collapse v-model="activeNames">
          <van-collapse-item
            v-for="(item, index) in record.transaction_records"
            :key="index"
            :name="index + 1"
          >
            <div slot="title">
              {{ item.SecShortName }}
              <van-tag plain>{{ item.As_Of_Date }}</van-tag>
            </div>
            <van-row>
              <van-col>
                日期：{{ item.As_Of_Date }}
                <br>
                所属行业：{{ item.Industry }}
                <br>
                买入价格：{{ item.Cost_Price }}
                <br>
                最近收盘价：{{ item.Close_Value }}
                <br>
                当日盈亏(百分比/金额)：{{ item.Today_Profit_and_Loss }} /
                {{ item.Today_profit }}
                <br>
                累积盈亏(百分比/金额)：{{ item.Total_Profit_and_Loss }} /
                {{ item.Total_Profit }}
              </van-col>
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </van-tab> -->
      <van-tab title="历史交易">
        <!-- 日期查询 -->
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="(item, index) in history_adjusts_data" :key="index" :name="index + 1">
            <div slot="title">
              股票代码：{{ item.ticker }}
              <van-tag plain>{{ item.asOfDate }}</van-tag>
            </div>
            <van-row>
              <van-col>
                日期：{{ item.asOfDate }}
                <br>
                股票代码：{{ item.ticker }}
                <br>
                成交均价：{{ item.costPrice }}
                <br>
                成交数量：{{ item.costAmount }}
                <br>
                交易类别：{{ item.transactionClass }}
              </van-col>
            </van-row>
          </van-collapse-item>
        </van-collapse>
      </van-tab>
    </van-tabs>
    <!-- <van-cel> </van-cel> -->
  </div>
</template>
<script>
import * as sysTools from '../../utils/sysTools'
import { Input } from 'cube-ui'
import {
  Row,
  Col,
  Card,
  Cell,
  CellGroup,
  Tab,
  Tabs,
  Tag,
  Switch,
  Toast,
  Dialog,
  Collapse,
  CollapseItem,
  Popup,
  DatetimePicker,
  Field,
  ActionSheet,
  Slider
} from 'vant'
import {
  islogin,
  toggleYidao,
  getUserInfofromOldServer,
  getUserInfofromNewServer,
  logout,
  fetchTradeRecord,
  fetchAdjustRecord,
  fetchTradeRecordWithDate,
  fetchAdjustRecordWithDate,
  fetchSecurities,
  applyYidao,
  autoApplymn,
  isvipApi,
  toggleLockMoney,
  history_adjusts,
  bind_state,
  customerReturnNew
} from '@/api/theApi'
import echarts from 'echarts'
import CryptoJS from 'crypto-js'
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Card.name]: Card,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Switch.name]: Switch,
    [Toast.name]: Toast,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [ActionSheet.name]: ActionSheet,
    [Slider.name]: Slider,
    [Input.name]: Input
  },
  data() {
    return {
      isNew: false,
      history_adjusts_data: [],
      special: false,
      lockmoneyShow: false,
      lockmoney: false,
      percent: 0,
      percentNew: 20,
      username: '',
      // loginstatus: false,
      phonenumber: '',
      editphoneShow: false,
      yidaochecked: false,
      yidaocheckedStatus: '',
      islogin: false,
      uCustomerReturn: {
        Profit_and_loss:'',
        Total_account:'',
        Total_return:'',
        Year_return:'',
        data:[]
      },
      uinfo1: '',
      comment: '',
      uinfo2: {
        phonenumber: this.phonenumber,
        regTime: '正在获取中',
        requiredFileds: 0,
        securities: null,
        securitiesCPass: '',
        securitiesName: '正在获取中',
        securitiesPassword: '****',
        securitiesUsername: '****',
        username: '正在获取中',
        vipStartTime: '正在获取中',
        vipStopTime: '正在获取中',
        trialExpirationTime: null
      },
      phoneDiasblededit: true,
      dialogmsg: '确定切换',
      activeNames2: [],
      activeNames: [],
      record: [],
      adjustrecord: [],
      sellingrecord: [],
      accname: '',
      chart: '',
      qrDate: new Date(),
      datePickerShow: false,
      minDate: new Date('2000', '01', '01'),
      maxDate: new Date(),
      active: 0,
      editsecuritiesShow: false,
      securitiesname: '',
      form: {
        securities: '',
        securitiesUsername: '',
        securitiesPassword: '',
        securitiesCpass: ''
      },
      curentIndex: 0,
      show: false,
      needCpass: false,
      actions: [],
      isvip: false,
      isfrozen: false,
      exptime: '',
      vipstoptime: ''
    }
  },
  computed: {
    stopTime() {
      if (this.uinfo2.vipStopTime) {
        return this.uinfo2.vipStopTime.split(' ')[0]
      }
      return '正在获取'
    },
    tryExpTime() {
      if (this.uinfo2.trialExpirationTime) {
        return this.uinfo2.trialExpirationTime.split('T')[0]
      }
      return '正在获取...'
    },
    theUA() {
      return sysTools.getUA()
    },
    isWeiXin() {
      if (this.theUA.indexOf('micromessenger') !== -1) {
        return true
      } else {
        return false
      }
    },
    cubeWidth: () => {
      return document.body.clientWidth
    },
    cubeHeight: () => {
      return document.body.clientHeight
    },
    devicePixelRatio: () => {
      return window.devicePixelRatio
    },
    theExpDate(datetime) {
      return datetime.split(' ')[0]
    }
  },
  watch: {
    yidaochecked: function(newVal) {
      console.log(newVal)
      if (this.yidaocheckedStatus === '当前未开启一刀智投' && newVal) {
        this.yidaocheckedStatus = '';
      }
      if (!newVal && this.yidaocheckedStatus === '') {
        this.yidaocheckedStatus = '当前未开启一刀智投';
      }
    }
  },
  created() {
    this.init().then(() => {
      if (this.uCustomerReturn.data.length > 0) {
        this.drawChart(this.uCustomerReturn.data)
      }
    })
  },

  updated() {
    this.isvipFun()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    async setLockPercent() {
      if (this.percentNew > 0) {
        const setRslt = await toggleLockMoney(
            this.phonenumber,
            this.percentNew
        )
        this.lockmoney = setRslt.data.lockmoney === 1
        this.percent = setRslt.data.lockpercent
      } else {
        this.lockmoney = false
      }

      this.lockmoneyShow = false
    },
    // 遮罩关闭时
    closelockmoneyShow() {
      this.lockmoney = false
    },
    lockmoneyNow() {
      this.lockmoneyShow = false
      if (this.lockmoney === false) {
        this.percent = 0
      }
    },
    async toggleLockmoney() {
      console.log(this.lockmoney)
      if (this.lockmoney === true) {
        // 如果切换未打开
        this.lockmoneyShow = true
        this.percentNew = 20

        // toggle to server
      } else {
        // 如果切换为关闭

        toggleLockMoney(this.phonenumber).then(() => {
          Toast.success('已关闭风险锁定')
          this.percent = 0
          this.lockmoney = false
        })
      }
    },

    async isvipFun() {
      const username = localStorage.getItem('username')
      const rslt = await isvipApi(username)
      if (rslt.data.isvip === '1') {
        console.log(rslt.data.isvip)
        this.isvip = true
      } else {
        this.isvip = false
      }
    },
    // 成为vip
    vip() {
      // 检验是否可以进入支付流程
      const url = '/pay?p=' + sysTools.b64encode(this.phonenumber)
      this.$router.push({
        path: url
      })
    },
    autoDemoAccount() {
      Dialog.alert({
        title: '提示',
        message: '我们将为您开通模拟账号'
      }).then(() => {
        autoApplymn('ss').then(res => {
          if (res.msg === 'success') {
            Dialog.alert({
              title: '申请成功',
              message: '立刻选择感兴趣的策略'
            }).then(() => {
              // on confirm
              this.$router.push({
                path: '/strategylist'
              })
            })
          } else {
            Toast.fail('申请失败，请稍后重试')
          }
        })
      })
    },
    dateOfDatetime(datetime) {
      const theDatetime = new Date(datetime)
      // console.log(theDatetime)
      const year = theDatetime.getFullYear() + '-'
      // console.log(year)
      const month = theDatetime.getUTCMonth() + 1 + '-'
      // console.log(month)
      const date = theDatetime.getDate()
      // console.log(date)
      return year + month + date
    },
    drawChart(data) {
      this.chart = echarts.init(window.document.getElementById('piga'))
      // console.log(this.strategyinfo.data.length)
      // let accname = this.uinfo1.account_name
      // const firstaccname = accname.substring(0, 1)
      // let encodename = ''
      // for (let index = 0; index < accname.length - 1; index++) {
      //  encodename = encodename + '*'
      // }
      // accname = firstaccname + encodename

      // this.accname = accname
      this.chart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['策略', this.uinfo1.account_name ? `TOP1-${this.accname}` : 'unknow']
          // data: ['策略', '同期最佳']
          data: ['策略']
        },
        dataZoom: [{
          startValue: ''
        },
          {
            type: 'inside'
          }
        ],

        xAxis: {
          // type: 'category',
          // boundaryGap: false,
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: data.map(item => {
            return item.asOfDate
          }),
          spliteLine: {
            show: true,
            lineStyle: {
              color: ['grey'],
              width: 1,
              type: 'solid'
            }
          }
        },
        yAxis: {
          // splitLine: {
          //   show: true,
          //   lineStyle: {
          //     color: ['grey'],
          //     width: 1,
          //     type: 'solid'
          //   }
          // }
          name: '累计收益率'
        },
        series: [
          // {
          //  name: '同期最佳',
          //  type: 'line',
          //  itemStyle: {
          //    color: 'rgb(28, 32, 134)'
          //  },
          //  data: data.map(function(item) {
          //    return item.returns
          //  }),
          //  areaStyle: {}
          // },
          {
            name: '策略',
            type: 'line',
            itemStyle: {
              color: 'rgb(255, 54, 0)'
            },
            data: data.map(function(item) {
              return item.responseRate
            }),
            areaStyle: {}
          }
        ]
      })
    },
    async init() {
      // todo 获取lock状态， 赋值给 lockmoney ,洗澡
      // 登陆是否过期
      const theislogin = await islogin()
      this.islogin = theislogin.login_status

      if (theislogin.login_status) {
        // 获取用户名

        const originUname = localStorage.getItem('username')
        this.username = originUname
        if (originUname) {
          console.log('islogin')
          // 获取用户数据
          const uinfo1 = await getUserInfofromOldServer()
          // 检测到其它页面带来的首次开启标志
          const openNow = this.$route.params.openNow
          let resltOfCheckStatus = await bind_state(originUname)
          resltOfCheckStatus = resltOfCheckStatus.data
          console.log(resltOfCheckStatus)
          // console.log(openNow)
          if (openNow === '1') {
            const resltOfToggle = await toggleYidao(1)
            console.log(resltOfToggle)
            if (resltOfToggle.data === 'success') {
              this.yidaochecked = true
              Toast.loading('正在开启')
              Dialog.alert({
                message: '恭喜您，已成功开启一刀智投。\n您的账户将在下一个交易日开启交易。\n您可以登录个人中心查看账户表现。'
              })
            }
          }
          // 如果信号为 0  ，关闭一刀智投
          if (openNow === '0') {
            const resltOfToggle = await toggleYidao(0)
            console.log(resltOfToggle)
            if (resltOfToggle.data === 'success') {
              this.yidaochecked = false
            }
          }
          //
          this.uinfo1 = uinfo1
          if (uinfo1.status_of_use === 1) {
            this.yidaochecked = true
          } else {
            this.yidaochecked = false
            if (!resltOfCheckStatus) {
              this.yidaocheckedStatus = '当前未开启一刀智投';
            }
          }
          if (resltOfCheckStatus) {
            this.yidaocheckedStatus = resltOfCheckStatus;
          }
          const securities = await fetchSecurities()
          this.securities = securities
          const actions = await securities.data

          this.actions = actions.filter(item => {
            return item.id !== 2
          }).filter(item => {
            return item.isSupportAI === 1
          })
          // 获取其它用户信息，手机号，券商等
          const uinfo2 = await getUserInfofromNewServer(this.username)
          if (!uinfo2.securitiesCpass) {
            uinfo2.securitiesCpass = '******'
          }
          this.uinfo2 = uinfo2.data
          const now = new Date()
          const regtime = new Date(uinfo2.data.regTime)
          const exptime = (now - regtime - 10 * 24 * 3600000) / 24 / 3600000
          this.exptime = parseInt(exptime)
          localStorage.setItem('exptime', this.exptime)
          // console.log('exp', (now - regtime - 5 * 24 * 3600 * 1000) / 360000)
          this.percent = uinfo2.data.lockpercent
          if (uinfo2.data.lockmoney === 0) {
            this.lockmoney = false
          }
          if (uinfo2.data.lockmoney === 1) {
            this.lockmoney = true
          }

          this.phonenumber = this.uinfo2.phonenumber
          // hardcode 可转债用户，通过手机号标记，
          const spList = ['18911754617', '18410203185', '18811405910', '13910522733', '18698739733', '16619862175']
          if (spList.indexOf(this.phonenumber) > -1) {
            this.special = true
          } else {
            this.special = false
          }
          const record = await fetchTradeRecord()
          this.record = record
          const adjustrecord = await fetchAdjustRecord()
          this.adjustrecord = adjustrecord
          const history_adjusts_data = await history_adjusts(originUname)
          this.history_adjusts_data = history_adjusts_data.data
          // 处理收益信息
          // DES加密
          const e = CryptoJS.enc.Utf8.parse("ioufweiofjsvs")
          let info = CryptoJS.DES.encrypt(this.username,e,{
            mode:CryptoJS.mode.ECB,
            padding:CryptoJS.pad.Pkcs7
          }).toString()
          info = encodeURIComponent(info)
          let customerReturns = await customerReturnNew(info)
          customerReturns = customerReturns.data
          let today = customerReturns.length-1
          if (customerReturns.length > 0){
            this.uCustomerReturn.Profit_and_loss=(customerReturns[today].profitAndLoss*1).toFixed(2)
            this.uCustomerReturn.Total_account=customerReturns[today].totalAccount
            this.uCustomerReturn.Total_return=(customerReturns[today].responseRate*100).toFixed(2)+"%"
            this.uCustomerReturn.Year_return=(customerReturns[today].yearReturn*100).toFixed(2)+"%"
            this.uCustomerReturn.data=customerReturns
          }
        } else {
          localStorage.clear()
          logout().then(() => {})
        }
      } else {
        Toast.fail('登陆过期')
        setTimeout(() => {
          this.$router.push({
            path: '/login'
          })
        }, 2000)
      }
    },
    onSelect(item, index) {
      this.securitiesname = item.name
      this.form.securities = item.id
      // console.log(item, index)
      // this.curentIndex = index
      this.securities = item.id
      if (item.requireFileds === 1) {
        this.needCpass = true
      }
      if (item.requireFileds === 0) {
        this.needCpass = false
      }
      this.show = false
    },
    thelogout() {
      logout().then(res => {
        localStorage.clear()
        // alert('退出成功')
        this.$router.push({ path: '/home' })
        // window.location.href = '/'
      })
    },
    toggleYidaoBtn() {
      // 到这里， yidaochecked 已经发生了变化
      // 所以 本地已经是开启状态时通知服务器开启，
      if (this.yidaochecked) {
        // 启用
        Toast.loading({
          mask: true,
          message: '正在为您启用一刀智投功能'
        })
        toggleYidao(1).then(res => {
          console.log(res)
          // this.yidaochecked = true
          console.log(this.yidaochecked)
        })
      } else {
        toggleYidao(0).then(res => {
          // console.log(res)
          this.yidaochecked = false
          console.log(this.yidaochecked)
        })
      }
    },
    choseStrategy() {
      this.$router.push({
        path: '/strategylist'
      })
    },
    setPhone() {
      // console.log('绑定手机号')
      Dialog.alert({
        message: '暂时不支持自助绑定'
      }).then(() => {
        // on close
      })
    },
    toreglogin() {
      this.$router.push({
        path: '/login'
      })
    },
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`
      } else if (type === 'month') {
        return `${value}月`
      } else if (type === 'day') {
        return `${value}日`
      }
      return value
    },
    selectSecurities() {
      this.show = true
    },
    confirmDate: async function() {
      this.datePickerShow = false
      const thedate = this.dateOfDatetime(this.qrDate)

      // const hisrslt = await fetchHistoryWithDate(this.$route.params.id, this.$route.params.cid, thedate)

      const record = await fetchTradeRecordWithDate(thedate)
      this.record = record
      // const adjustrslt = await fetchWarehouseHistoryWithDate(this.$route.params.id, this.$route.params.cid, thedate)
      const adjustrecord = await fetchAdjustRecordWithDate(thedate)
      this.adjustrecord = adjustrecord
    },
    cancelDate: function() {
      this.datePickerShow = false
    },
    editSecurities: async function() {
      const data = {
        nativeCode: 'imAinvestcnabc',
        phonenumber: this.uinfo2.phonenumber,
        securities: this.securities,
        // securitiesCpass: this.form.securitiesCpass,
        securitiesPassword: this.form.securitiesPassword,
        securitiesUsername: this.form.securitiesUsername,
        ua: this.theUA
      }
      if (this.needCpass) {
        data['securitiesCpass'] = this.form.securitiesCpass
      }
      if (this.form.securities === 7) {
        data['comment'] = this.comment
      }
      Toast.loading({
        mask: true,
        message: '加载中...'
      })
      console.log(data)

      // 前面加上条件，存在空值不允许提交
      // const applyRslt = await applyYidao(data) todo
      if (data.securitiesUsername.trim() === "" || data.securitiesPassword.trim() === "") {
        Toast.fail('表单不能为空');
        return
      }
      if (this.form.securities === 7 && data.comment.trim() === "") {
        Toast.fail('表单不能为空');
        return
      }
      const applyRslt = await applyYidao(data);
      if (applyRslt.msg === 'success') {
        // Toast.success('成功提交申请')
        Dialog.alert({
          title: '设置成功',
          message: '新的交易日将尝试使用您设置的券商账号进行交易'
        }).then(() => {
          // on confirm
          this.editsecuritiesShow = false
          window.location.href = '/user'
        })
      } else {
        Toast.fail('申请失败')
        // 提示申请失败
      }
    }
  }
}
</script>
<style lang="less" scoped>
.card {
  margin: 5px;
  padding: 2px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 8px 12px #ebedf0;
}

.cardline {
  margin: 0 0 5px 10px;
}

input[disabled] {
  filter: alpha(opacity=100);
  opacity: 1;
  background: #fff;
}

.custom-button {
  width: 10vw;
  color: #fff;
  font-size: 10px;
  line-height: 3vh;
  text-align: center;
  background-color: #ee5f41;
  border-radius: 100px;
}

.yidaocheckedStatus {
  font-size: 12px;
  color: rgb(150, 150, 150);
}

.cellTips {
  position: absolute;
  top: 5px;
  z-index: 100;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  padding: 0 10px;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.5);

}

.cellTips::after {
  content: '';
  position: absolute;
  left: -6px;
  bottom: 4px;
  width: 0;
  height: 0;
  border-right: 6px solid rgba(0, 0, 0, 0.5);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
</style>